import * as React from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Popover, Transition } from "@headlessui/react"
import {
  MenuAlt2Icon,
  XIcon,
  FingerPrintIcon,
  AtSymbolIcon,
} from "@heroicons/react/outline"

const Header = () => {
  const { allDataJson } = useStaticQuery(
    graphql`
      {
        allDataJson(filter: { data: { eq: "header" } }) {
          edges {
            node {
              logo {
                publicURL
              }
              company
              cat
              links {
                text
                slug
                icon
              }
            }
          }
        }
      }
    `
  )

  const data = allDataJson.edges[0].node

  const icons = {
    FingerPrintIcon,
    AtSymbolIcon,
  }

  const Icon = props => {
    const { name } = props

    const TheIcon = icons[name]
    return <TheIcon {...props} />
  }

  return (
    <header>
      <Popover className="relative bg-white">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Top"
        >
          <div className="flex items-center justify-between w-full py-6">
            <div className="flex items-center">
              <Link
                to="/"
                className="flex items-center space-x-5 text-3xl font-semibold leading-none contain"
              >
                <img
                  className="w-auto h-10"
                  src={data.logo.publicURL}
                  alt="Logo"
                />
                <span className="font-bold text-transparent bg-gradient bg-clip-text">
                  {data.company}
                </span>
              </Link>
              {/* Desktop Links */}
              <div className="hidden ml-10 space-x-8 lg:block">
                {data.links.map(link => (
                  <Link
                    key={link.text}
                    to={`/${link.slug}`}
                    className="text-lg text-gray-500 hover:underline"
                  >
                    {link.text}
                  </Link>
                ))}
              </div>
            </div>
            {/* Mobile Links */}
            <div className="ml-auto bg-white lg:hidden">
              <Popover.Button
                type="button"
                className="flex items-center px-3 py-2 text-blue-500 hover:text-blue-700"
                aria-expanded="false"
              >
                <span className="sr-only">Open menu</span>
                <MenuAlt2Icon className="w-6 h-6" aria-hidden="true" />
              </Popover.Button>

              <Transition
                as={React.Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  className="absolute inset-x-0 top-0 p-2 transition origin-top-right transform lg:hidden"
                >
                  <div className="bg-white divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50 lg:hidden">
                    <div className="px-6 pt-6">
                      <div className="transition duration-1000 ease-out">
                        <div className="divide-y-2 divide-gray-200">
                          <div className="grid grid-cols-1 gap-10">
                            <div className="inline-flex gap-5">
                              <img
                                className="w-auto h-8"
                                src={data.logo.publicURL}
                                alt="Logo"
                              />
                              <span className="text-2xl font-bold text-transparent bg-gradient bg-clip-text">
                                {data.company}
                              </span>
                              <Popover.Button
                                className="flex items-center px-3 py-2 ml-auto text-blue-500 hover:text-blue-700"
                                aria-expanded="false"
                              >
                                <span className="sr-only">Close menu</span>
                                <XIcon className="w-6 h-6" aria-hidden="true" />
                              </Popover.Button>
                            </div>
                            {data.links.map(link => {
                              return (
                                <Link
                                  key={link.text}
                                  to={`/${link.slug}`}
                                  className="inline-flex items-center p-3 -m-3 space-x-5"
                                >
                                  <div className="flex items-center">
                                    <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 p-1 rounded-md bg-gradient text-gray-50">
                                      <Icon name={link.icon} />
                                    </div>
                                    <div className="ml-4 text-lg font-bold text-gray-900">
                                      {link.text}
                                    </div>
                                  </div>
                                </Link>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="py-6">
                        <div className="mt-6">
                          <Link
                            to="/contact"
                            className="w-full transform btn text-gray-50 bg-gradient focus-ring hover:scale-105"
                          >
                            {data.cat}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </div>
          </div>
        </nav>
      </Popover>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
